import React from "react"
import { socialInfo } from "../../js/utils"

// Components
import Back from "../partials/svg/back"

// Markup
const About = () => {
  return (
    <>
    <div id="about">
        <Back url="/" styleClass="back-link" text="accueil"/>
        <div className="container">
          <section className="main-page-content">
            <h1>Whoami?</h1>
            <p>
              Je suis un jeune développeur passionné par le domaine du numérique et ses activités diverses et variées. J'étudie actuellement à
              <a href="https://www.42lausanne.ch/">42 Lausanne</a>.
            </p>
            <ul>
              <li>
                <a className="btn" href={socialInfo.linkedin}>
                  LinkedIn
                </a>
              </li>
              <li>
                <a className="btn" href={socialInfo.github}>
                  GitHub
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <div className="section-separator"></div>
    </>
  )
}
export default About