import React from "react"

// Components
import Layout from "../components/layout/layout"
import SEO from "../components/helmet/seo"
import About from "../components/page-partials/about"
import Skills from "../components/page-partials/skills"

// Markup
const AboutPage = () => {
  return (
    <Layout mainColor="#000814" styleClass="about">
      <SEO title="Whoami?"/>
      <About />
      <Skills />
    </Layout>
  )
}
export default AboutPage
