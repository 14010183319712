import React from "react"

// Components

// Markup
const Skills = () => {
  return (
    <div id="skills" className="container">
        <section className="main-page-skills">
            <h2>Je parle couramment</h2>
              <ul>
                <li><h5><span style={{color: "#E34F26"}}>.</span>html</h5></li>
                <li><h5><span style={{color: "#1572B6"}}>.</span>css</h5></li>
                <li><h5><span style={{color: "#F7DF1E"}}>.</span>js</h5></li>
                <li><h5><span style={{color: "#777BB4"}}>.</span>php</h5></li>
                <li><h5><span style={{color: "#CC342D"}}>.</span>rb</h5></li>
                <li><h5><span style={{color: "#3F51B5"}}>.</span>c</h5></li>
              </ul>
          </section>
      </div>
  )
}
export default Skills
